<script>
	export let categories
	export let multiple = false
	export let selectedValues = null

	import { createEventDispatcher } from "svelte"
	import { TOUT_AFRIQUE_SLUG } from "~/config.js"
	import { sanitizeString, sortBy } from "~/utils.js"
	const dispatch = createEventDispatcher()

	let textFilter = ""

	$: displayedCategories = categories
		.map((category) => {
			const newCategory = { ...category }
			const textFilterClean = sanitizeString(textFilter)
			if (textFilter) {
				const filteredButtons = category.buttons.filter(
					(button) =>
						!textFilterClean ||
						sanitizeString(button.label).search(textFilterClean) !== -1,
				)
				if (filteredButtons.length === 0) {
					return
				}
				newCategory.buttons = filteredButtons
				newCategory.open = true
			}
			newCategory.buttons = newCategory.buttons.map((button) => ({
				...button,
				isSelected: selectedValues && selectedValues.includes(button.id),
			}))
			return newCategory
		})
		.filter(Boolean)

	function isSelected(id) {
		return (selectedValues || []).includes(id)
	}

	function selectValue(id) {
		selectedValues = [...(selectedValues || []), id]
		if (id === TOUT_AFRIQUE_SLUG) toutAfriqueButton.isSelected = true
	}

	function deselectValue(id) {
		selectedValues = selectedValues || []
		const index = selectedValues.indexOf(id)
		if (index !== -1) {
			selectedValues = [
				...selectedValues.slice(0, index),
				...selectedValues.slice(index + 1),
			]
		}
		if (id === TOUT_AFRIQUE_SLUG) toutAfriqueButton.isSelected = false
	}

	function handleButtonClick(event, button) {
		const { id } = button
		if (multiple) {
			if (isSelected(id)) {
				deselectValue(id)
			} else {
				selectValue(id)
			}
		} else {
			dispatch("select", id)
		}
	}

	function handleDeselectAllClick() {
		selectedValues = []
		toutAfriqueButton.isSelected = false
	}

	const toutAfriqueButton = {
		id: TOUT_AFRIQUE_SLUG,
		label: "Afrique",
		isSelected: isSelected(TOUT_AFRIQUE_SLUG),
	}
</script>

<div class="my-4 space-y-3 flex flex-col">
	<input
		class="border h-8 px-2 rounded w-full"
		type="search"
		placeholder="Rechercher..."
		bind:value={textFilter} />
	{#if multiple}
		<p class="border-gray-400 border-l-4 my-6 pl-4 py-4 text-gray-700">
			Vous pouvez sélectionner ou désélectionner les indicateurs en cliquant sur
			leur nom.
		</p>
		<div class="space-x-2 self-end">
			<button class="btn" on:click={handleDeselectAllClick}>
				Tout désélectionner
			</button>
			<button
				class="btn btn-primary"
				on:click={() => dispatch('select', selectedValues)}>
				Valider
			</button>
		</div>
	{/if}
</div>

<ul class="flex flex-wrap ml-3">
	<li class="m-2">
		<button
			on:click={(event) => handleButtonClick(event, toutAfriqueButton)}
			class="btn"
			class:selected={toutAfriqueButton.isSelected}
			disabled={!multiple && toutAfriqueButton.isSelected}
			tabindex="-1">
			{toutAfriqueButton.label}
		</button>
	</li>
</ul>

{#each displayedCategories as category (category.id)}
	<details
		class="my-4"
		open={category.open === undefined ? true : category.open}>
		<summary class="text-xl">{category.label}</summary>
		<ul class="flex flex-wrap ml-3">
			{#each category.buttons.sort(sortBy('label')) as button (button.id)}
				<li class="m-2">
					<button
						on:click={(event) => handleButtonClick(event, button)}
						class="btn"
						class:selected={button.isSelected}
						disabled={!multiple && button.isSelected}
						tabindex="-1">
						{button.label}
					</button>
				</li>
			{/each}
		</ul>
	</details>
{/each}

<style lang="postcss">.selected {
  text-decoration: underline;
  border-width: 2px;
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
  font-weight: 700;
  @apply underline border-2 border-gray-700 font-bold selected text-gray-900;
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}</style>
