<script>
	export let dataAtYear

	import { onDestroy } from "svelte"
	import { pyramidBuilder, removeTooltips } from "./popPyramid.js"

	let containerNode
	let clientWidth = 600

	$: height = Math.floor(clientWidth * (2 / 3))

	$: options = {
		height,
		width: clientWidth,
		style: {
			leftBarColor: "#229922",
			rightBarColor: "#992222",
		},
	}

	$: if (dataAtYear) {
		if (containerNode && containerNode.textContent) {
			containerNode.textContent = ""
		}
		pyramidBuilder(dataAtYear, containerNode, options)
	}

	onDestroy(() => {
		removeTooltips()
	})
</script>

<div class="overflow-x-auto">
	<div bind:clientWidth class="pyramid-chart">
		<div bind:this={containerNode} />
	</div>
</div>

<style>.pyramid-chart {
  /* The D3 chart does not render well under 500px. For example, bars height can become negative. */
  min-width: 500px;
}</style>
