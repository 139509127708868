<script>
	import clsx from "clsx"
	import { createEventDispatcher } from "svelte"
	import { page, url } from "@sveltech/routify"
	import { menu, x } from "~/heroicons.js"
	import { navLinks } from "~/nav.js"
	import { isDesktop, showSideBar } from "~/stores.js"
	import Brand from "~/components/Brand.svelte"
	import LoadIndicator from "./LoadIndicator.svelte"
	import SvgIcon from "~/components/SvgIcon.svelte"

	const dispatch = createEventDispatcher()

	$: classes = clsx(
		"top-0 inset-x-0 z-10 h-16 flex items-center bg-africartes-blue pl-4 pr-0 sm:pr-4 py-3 shadow-xl",
		$showSideBar || $isDesktop ? "fixed" : "absolute",
	)

	$: leftNavLinks = navLinks.filter(({ position }) => position === "left")
	$: rightNavLinks = navLinks.filter(({ position }) => position === "right")
</script>

<nav class={classes}>
	<button
		class="mr-4 sm:hidden text-africartes-gray"
		on:click={() => {
			dispatch('menu-toggle')
		}}>
		<SvgIcon size={24} {...$showSideBar ? x : menu} />
	</button>

	<div class="sm:flex-shrink-0">
		<Brand
			on:click={() => {
				$showSideBar = false
			}} />
	</div>

	<div
		class="hidden sm:flex flex-grow justify-center space-x-2 text-lg font-bold tracking-wider">
		{#each leftNavLinks as { routeName, pageName, text } (routeName)}
			<a
				class={clsx('nav-link', $page.meta.name === pageName && 'selected')}
				href={$url(routeName)}>
				{text}
			</a>
		{/each}
	</div>

	<div class="ml-auto">
		<LoadIndicator />
	</div>

	<div class="hidden sm:block space-x-2">
		{#each rightNavLinks as { routeName, pageName, text } (routeName)}
			<a
				class={clsx('nav-link', $page.meta.name === pageName && 'selected')}
				href={$url(routeName)}>
				{text}
			</a>
		{/each}
	</div>

	<div class="hidden sm:block ml-2 flex-shrink-0">
		<a href="https://www.afd.fr" target="_blank" rel="noopener">
			<img
				src={$url('/logo-afd-blanc-fond-transparent.png')}
				alt="logo AFD"
				class="w-20" />
		</a>
	</div>
</nav>

<style lang="postcss">.nav-link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  --text-opacity: 1;
  color: rgb(229, 232, 236);
  color: rgba(229, 232, 236, var(--text-opacity));
  white-space: nowrap;
}

.nav-link:hover {
  --bg-opacity: 1;
  background-color: rgb(229, 232, 236);
  background-color: rgba(229, 232, 236, var(--bg-opacity));
  --text-opacity: 1;
  color: #2d2264;
  color: rgba(45, 34, 100, var(--text-opacity));
}

.nav-link.selected {
  --text-opacity: 1;
  color: #2d2264;
  color: rgba(45, 34, 100, var(--text-opacity));
  --bg-opacity: 1;
  background-color: rgb(229, 232, 236);
  background-color: rgba(229, 232, 236, var(--bg-opacity));
}</style>
