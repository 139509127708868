<script>
	export let value1
	export let value2
	export let years

	import NoUiSlider from "@woden/svelte-nouislider"
	import { play, pause } from "~/heroicons.js"
	import SvgIcon from "~/components/SvgIcon.svelte"

	$: minYear = years[0]
	$: maxYear = years[years.length - 1]

	let interval

	function handlePlayClick() {
		if (interval) {
			return
		}
		interval = window.setInterval(() => {
			const delta = value2 - value1
			let indexValue1 = years.indexOf(value1)
			let indexValue2 = years.indexOf(value2)
			if (indexValue2 + 1 <= years.length - 1) {
				indexValue1++
				indexValue2++
			} else {
				indexValue1 = 0
				indexValue2 = delta
			}
			value1 = years[indexValue1]
			value2 = years[indexValue2]
			setSliderYear(value1, value2)
		}, 1000)
	}

	function handlePauseClick() {
		window.clearInterval(interval)
		interval = null
	}

	let sliderRef

	function handleSliderUpdate(event) {
		const index = Math.round(event.detail.unencoded[0]) // "unencoded" can contain values like 5.00000001
		const year = years[index]
		// keep tooltips to recover value1 and value2
		const toolTips = sliderRef
			.getApi()
			.target.getElementsByClassName("noUi-tooltip")
		const valTooltip2 = parseInt(
			toolTips[1].parentNode.getAttribute("aria-valuetext"),
		)
		const year2 = years[valTooltip2]
		// don't display tooltips
		toolTips[0].setAttribute("style", "display: none")
		toolTips[1].setAttribute("style", "display: none")
		value1 = year
		value2 = year2
	}

	function setSliderYear(year1, year2) {
		if (!sliderRef) {
			return
		}
		const indexYear1 = years.indexOf(year1)
		const indexYear2 = years.indexOf(year2)
		if (indexYear1 !== -1 && indexYear2 !== -1) {
			sliderRef.set([indexYear1, indexYear2])
		}
	}
</script>

{#if years.length > 1}
	<div class="flex items-end">
		<div class="flex-grow">
			<div class="flex justify-between mb-4">
				<span>{minYear}</span>
				<span class="pill">entre {value1} et {value2}</span>
				<span>{maxYear}</span>
			</div>
			<div class="mx-6 relative" style="top: -8px">
				<NoUiSlider
					bind:this={sliderRef}
					start={[years.indexOf(value1), years.indexOf(value2)]}
					behaviour="drag"
					connect={true}
					tooltips={[true, true]}
					range={{ min: 0, max: years.length - 1 }}
					step={1}
					on:update={handleSliderUpdate} />
			</div>
		</div>
		<div class="ml-4 text-gray-800 relative" style="top: 1px">
			{#if interval}
				<button on:click={handlePauseClick}>
					<SvgIcon size={32} {...pause} />
				</button>
			{:else}
				<button on:click={handlePlayClick}>
					<SvgIcon size={32} {...play} />
				</button>
			{/if}
		</div>
	</div>
{:else if value1}
	<div class="flex justify-center">année {value1}</div>
{/if}

<style lang="postcss">.pill {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 9999px;
}</style>
