<script>
	export let value
	export let years

	import NoUiSlider from "@woden/svelte-nouislider"
	import { play, pause } from "~/heroicons.js"
	import SvgIcon from "~/components/SvgIcon.svelte"
	import { createEventDispatcher } from "svelte"

	const dispatch = createEventDispatcher()

	$: minYear = years[0]
	$: maxYear = years[years.length - 1]

	let interval

	function handlePlayClick() {
		if (interval) {
			return
		}
		interval = window.setInterval(() => {
			const index = years.indexOf(value)
			value = years[(index + 1) % years.length]
			setSliderYear(value)
		}, 1000)
	}

	function handlePauseClick() {
		window.clearInterval(interval)
		interval = null
	}

	let sliderRef

	function handleSliderUpdate(event) {
		const index = Math.round(event.detail.unencoded[0]) // "unencoded" can contain values like 5.00000001
		const year = years[index]
		value = year
		dispatch("update", value)
	}

	function setSliderYear(year) {
		if (!sliderRef) {
			return
		}
		const index = years.indexOf(year)
		if (index !== -1) {
			sliderRef.set(index)
		}
	}
</script>

{#if years.length > 1}
	<div class="flex items-end">
		<div class="flex-grow">
			<div class="flex justify-between mb-4">
				<span>{minYear}</span>
				<span class="pill">{value}</span>
				<span>{maxYear}</span>
			</div>
			<div class="mx-6 relative" style="top: -8px">
				<NoUiSlider
					bind:this={sliderRef}
					start={years.indexOf(value)}
					range={{ min: 0, max: years.length - 1 }}
					step={1}
					on:update={handleSliderUpdate} />
			</div>
		</div>
		<div class="ml-4 text-gray-800 relative" style="top: 1px">
			{#if interval}
				<button on:click={handlePauseClick}>
					<SvgIcon size={32} {...pause} />
				</button>
			{:else}
				<button on:click={handlePlayClick}>
					<SvgIcon size={32} {...play} />
				</button>
			{/if}
		</div>
	</div>
{:else if value}
	<div class="flex justify-center">année {value}</div>
{/if}

<style lang="postcss">.pill {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 9999px;
}</style>
